import axios from "axios";
import json from "../config.json";

export default function petitionPatch(key, value) {
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
  var url;
  var data;

  if (token) {
    switch (key) {
      case "contact":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/contacts/${value.contact_id}`;
        data = value.data;
        break;
      case "editNote":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/contact/notes/${value.note_id}`;
        data = value.data;
        break;
      case "userDetails":
        url = urlBase + `/itpvoice/v2/${id}/my-extension`;
        data = value.data;
        break;
      case "contactList":
        url =
          urlBase +
          `/360/accounts/${id}/my-account/contact-lists/${value.contact_list_id}`;
        data = value.data;
        break;
      case "tag":
        url = urlBase + `/360/accounts/${id}/my-account/tags/${value.tag_id}`;
        data = value.data;
        break;
      case "updateMultipleContacts":
        url = urlBase + `/360/accounts/${id}/my-account/bulk/contacts${value?.parameter || ""}`;
        data = value.data;
        break;
      case "leads":
        url = urlBase + `/360/accounts/${id}/my-account/leads/${value.lead_id}`;
        data = value.data;
        break;
      case "stage":
        url =
          urlBase +
          `/360/accounts/${id}/funnels/${value.funnel_id}/stages/${value.stage_id}`;
        data = value.data;
        break;
      case "aiEnabled":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/message-thread/${value.thread_id}`;
        data = value.data;
        break;
      case "accountDetails":
        url = urlBase + `/itpvoice/v2/${id}`;
        data = value.data;
        break;
      case "webhook":
        url = urlBase + `/itpvoice/v2/${id}/webhooks/${value.webhookId}`;
        data = value.data;
        break;
      case "brands":
        url = urlBase + `/itpvoice/v2/${id}/brands/${value.brand_id}`;
        data = value.data;
        break;
      case "campaign":
        url = urlBase + `/itpvoice/v2/${id}/csp-campaign/${value.brand_id}`;
        data = value.data;
        break;
      case "callFlow":
        url = urlBase + `/itpvoice/v2/${id}/callflows/${value.callflow_id}`;
        data = value.data;
        break;
      case "numberEdit":
        url = urlBase + `/itpvoice/v2/${id}/numbers/${value.number}`;
        data = value.data;
        break;
      case "orderItemUpdate":
        url = urlBase + `/crm/orderitems/${value.order_item_id}`;
        data = value.data;
        break;
      case "timeGroup":
        url = urlBase + `/itpvoice/v2/${id}/time-groups/${value.time_id}`;
        data = value.data;
        break;
      case "timeRules":
        url = urlBase + `/itpvoice/v2/${id}/time-rules/${value.time_id}`;
        data = value.data;
        break;
      case "user":
        url = urlBase + `/itpvoice/v2/${id}/users/${value.user_id}`;
        data = value.data;
        break;
      case "queue":
        url = urlBase + `/itpvoice/v2/${id}/queues/${value.queue_id}`;
        data = value.data;
        break;
      case "switch":
        url = urlBase + `/itpvoice/v2/${id}/switches/${value.switch_id}`;
        data = value.data;
        break;
      case "messageTemplates":
        url =
          urlBase +
          `/360/accounts/${id}/message-templates/${value.message_template_id}`;
        data = value.data;
        break;
      case "leadSource":
        url =
          urlBase + `/360/accounts/${id}/leads/sources/${value.source_lead_id}`;
        data = value.data;
        break;
      case "operationPanelGroups":
        url =
          urlBase +
          `/itpvoice/v2/${id}/operator-panel/groups/${value.group_id}`;
        data = value.data;
        break;
      case "customField":
        url =
          urlBase +
          `/360/accounts/${id}/contacts/custom-fields/${value.custom_field_id}`;
        data = value.data;
        break;
      case "smartCampaign":
        url =
          urlBase +
          `/itpvoice/v2/${id}/my-extension/smart-campaigns/${value.campaign_id}`;
        data = value.data;
        break;
      case "campaignNumbers":
        url =
          urlBase +
          `/itpvoice/v2/${id}/csp-campaign/${value.campaign_id}/numbers`;
        data = value.data;
        break;
      case "aiDetailsUpdate":
        url = urlBase + `/ai/accounts/${id}`;
        data = value.data;
        break;
      case "voicemailUserSetings":
        url = urlBase + `/itpvoice/v2/${id}/my-extension/voicemail`;
        data = value.data;
        break;
      case "funnel":
        url = urlBase + `/360/accounts/${id}/funnels/${value?.funnel_id}`;
        data = value.data;
        break;
      case "optOutKeyword":
        url =
          urlBase + `/itpvoice/v2/${id}/opt-out-keywords/${value?.keyword_id}`;
        data = value.data;
        break;
      case "salesLead":
        url = urlBase + `/ai/accounts/${id}/question-list/${value?.sale_id}`;
        data = value.data;
        break;
      case "faqs":
        url = urlBase + `/ai/accounts/${id}/faqs/${value?.faqs_id}`;
        data = value.data;
        break;
      case "salesLeadQuestions":
        url =
          urlBase +
          `/ai/accounts/${id}/screening-question/${value?.question_id}`;
        data = value.data;
        break;
      case "aiFollowUpSchedule":
        url =
          urlBase +
          `/ai/accounts/${id}/follow-up/schedule/${value?.schedule_id}`;
        data = value.data;
        break;
      case "changeDataUsers":
        url = urlBase + `/crm/contacts/${value.crm_contact_id}`;
        data = value.data;
        break;
      case "NewCreditcard":
        url = urlBase + `/billing/account/${value.billing_api_id}`;
        data = value.data;
        break;
      case "voicemailAdmins":
        url = urlBase + `/itpvoice/v2/${id}/voicemails/${value.user_id}`;
        data = value.data;
        break;
      case "smartCampaignLogs":
        url = urlBase + `/360/accounts/${id}/smart-campaigns/${value.campaign_id}/tasks/bulk-actions${value?.parameter || ""}`;
        break;
      case "aiAgent":
        url = urlBase + `/ai/accounts/${id}/ai_agent/${value.aiAgentID}`;
        data = value.data;
        break;
      case "integrationFields":
        url = urlBase + `/integrations/accounts/${id}/customer-integrations/${value.integrationId}`;
        data = value.data;
        break;
      default:
        return "error";
    }

    var config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };

    return axios.patch(url, data, config);
  }
}
