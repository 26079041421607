import petitionGet from "../../../../../../services/petitionGet";
import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";

const fetchNumbers = async () => {
  const { data: result } = await petitionGet("viewNumbers");
  result.result.forEach((element) => {
    element.description = element?.description || "";
    element.statusReal = element.itpvoice_metadata ? element.itpvoice_metadata.state.replace("_", " ") : "null";
    element.formatedNumber = formatPhoneNumber(element.number)
    element.usedByCallFlow = !element?.in_use ? "Not assigned!" : !element?.in_use_by?.internal_use ? element?.in_use_by?.name : ""
    element.style = element?.in_use ? {} : {
      background: "rgba(255, 0, 0, 0.2) 0% 0% no-repeat padding-box",
      borderRadius: "12px",
      font: "normal normal normal 12px/16px Instrument Sans",
      letterSpacing: "0px",
      color: "#FF0000",
      opacity: "1",
    }
  });

  return result.result
};

export default fetchNumbers;
