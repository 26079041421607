import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { AlignedRow, CollapsePanel, SearchBoxFilter } from "../../../../../../components";
import { useDynamicFunction } from "../../../../../../contexts/DynamicFunctionContext";
import { checkNumberService, fetchCallflowDetails, fetchCallflowNumbers, handleBlurInputNameService } from "../../services";
import { handleChangeSearchNumber } from "../../utils"
import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";
import Switch from "react-switch";
import "./EditCallFlowNumbers.css";
import Skeleton from "react-loading-skeleton";

const EditCallFlowNumbers = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pk = searchParams.get("pk");

  const { setDynamicFunction } = useDynamicFunction();
  const navigate = useNavigate();

  const [callFlowDetails, setCallFlowDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [allData, setAllData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [formNumbersSwitch, setFormNumbersSwitch] = useState({});
  const [callflowName, setCallflowName] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [numbers, setNumbers] = useState([1, 2, 3])

  const handleChangeSearch = (e) => {
    if (loading) return;

    handleChangeSearchNumber(e, setSearchValue, allData, setData)
  };

  const handleCheck = async (element) => {
    await checkNumberService(selectedUsers, element, setFormNumbersSwitch, setSelectedUsers, callFlowDetails, callflowName)
  };

  const isCheckedNumber = (element) => {
    return !!formNumbersSwitch[element?.number?.number];
  };

  const handleChangeName = (e) => {
    const { value } = e.target;
    setCallflowName(value);
  };

  const handleBlurInputName = async () => {
    await handleBlurInputNameService(callflowName, currentName, selectedUsers, setCurrentName, callFlowDetails)
  };

  const redirectToCallFlowNumbers = (element) => {
    const callFlowID = element?.number?.in_use_by?.pk
    navigate(`/Advanced-Call-flows/Edit-Numbers?pk=${callFlowID}`)
  }

  const petition = async (callFlowDetails) => {
    await fetchCallflowNumbers(callFlowDetails, setLoading, setFormNumbersSwitch, setSelectedUsers, setData, setAllData)
  };

  const getData = async () => {
    setLoading(true);
    await fetchCallflowDetails(pk, setCallFlowDetails, petition, setCallflowName, setCurrentName);
  };

  useEffect(() => {
    if (data) setNumbers(data)
  }, [data])

  useEffect(() => {
    if (!pk) {
      navigate("/Advanced-Call-flows");
    } else {
      setDynamicFunction(() => () => {
        navigate("/Advanced-Call-flows");
      });

      getData();
    }
  }, [pk]);

  return (
    <div className="mt-5">
      <AlignedRow>
        <h6 className="itp-accordion-header-subtitle">
          Create templates that can be used inside workflows or messages.
        </h6>
      </AlignedRow>

      <AlignedRow>
        <h3 className="itp-accordion-header-title">Call flow : {currentName}</h3>
      </AlignedRow>

      <AlignedRow className="mt-2">
        <SearchBoxFilter
          handleChange={handleChangeSearch}
          value={searchValue}
          placeholder="Search Number"
        />
      </AlignedRow>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        title="Numbers"
        subtitle="Rename callflow, assign or remove numbers"
        className="mb-5"
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Name</label>
            <label className="itp-edit-number-label-2">
              Change the name of the specific call flow
            </label>
          </div>

          {loading ? (
            <div className="itp-edit-number-input">
              <Skeleton width="100%" height="2rem"/>
            </div>
          ) : (
            <input
              placeholder="Add Name"
              className="form-control-itp itp-edit-number-input"
              name="name"
              value={callflowName}
              onChange={handleChangeName}
              onBlur={handleBlurInputName}
            />
          )}
        </div>

        <div className="itp-edit-callFlow-numbers__numbers">
          {numbers.map((element, i) => (
            <div key={i} className="d-flex justify-content-between mt-4">
              {loading ? (
                <Skeleton height="2rem" width="10rem" />
              ) : (
                <label className="itp-edit-number-label-1">
                  {formatPhoneNumber(element?.number?.number)}
                </label>
              )}

              {loading ? (
                  <div className="d-flex justify-content-end">
                    <Skeleton style={{ marginRight: "2rem" }} width="5rem" height="2rem" />
                  </div>
                ) : (
                  <>
                    {!isCheckedNumber(element) && element?.number?.in_use && element?.number?.in_use_by?.internal_use === false ? (
                      <div onClick={() => redirectToCallFlowNumbers(element)} className="cursor-pointer">
                        <label className="itp-edit-number-label-1 cursor-pointer">
                          Number be used in callflow {element?.number?.in_use_by?.name}
                        </label>
                      </div>
                    ) : (
                      <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                        <Switch
                          className="itp-custom-table-switch itp-edit-callFlow-numbers-switch"
                          onColor="#626ed4"
                          height={20} // Ajusta la altura según tus necesidades
                          width={40}
                          checked={isCheckedNumber(element)}
                          onChange={() => handleCheck(element)}
                        />
                      </div>
                    )}
                  </>
                )}
            </div>
          ))}
        </div>
      </CollapsePanel>
    </div>
  );
};

export default EditCallFlowNumbers;
